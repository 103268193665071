import React, { useState } from "react";
import { Box, Grid, IconButton, Toolbar, AppBar as Bar, createTheme, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import Logo from "../../assets/hailscale-logo-color.svg";
import { ROUTES } from "../../constants/routes";
import Menu from "../Menu";
import { COLORS } from "../../constants/colors";
import CalendarPicker from "../Common/CalendarPicker";
import { Fragment } from "react";
import Phone_Black from "../../assets/Phone_Black.webp";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { ELEMENT_SIZES } from "../../constants/elementSizes";
import { DynamicHamburger } from "../Common/DynamicHamburger";

const StyledLogo = styled.img`
  height: ${props => props.ismedium === "true" ? "40px" : "25px"};
`;

const StyledHeaderContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledBar = styled(Bar)`
${props => props.ismedium === "true" ? `
height: ${ELEMENT_SIZES.headerHeight.lg}px;
padding-left: 22px;
`: `
  height: ${ELEMENT_SIZES.headerHeight.sm}px;
  padding-left: 15px;
`}
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`;

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
  height: 100%;
  div { height: 100%; }
  .call_in_header {
    ${props => props.ismedium === "true" ? "width: 400px;" : ""}
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    ${props => props.ismedium === "true" ? `
    border-right: 1px solid ${COLORS.LIGHT_BLUE};
    border-left: 1px solid ${COLORS.LIGHT_BLUE};
    padding: 0 30px;
    `: `
    `}
    
    p {
      font-family: Colfax Bold !important;
      margin:0;
      margin-bottom: 10px;
    }
    a {
      text-decoration: none;
      color: ${COLORS.BLACK};
      &:hover {
        transform: scale(1.02);
        transition: all .3s;
      }
      span {
        text-decoration: underline;
        text-decoration-thickness: from-font;
        text-decoration-skip-ink: none;
      }
      ${props => props.ismedium === "true" ? `
        border-radius: 10px;
        border: 1px solid ${COLORS.LIGHT_BLUE};
        padding: 13px 21px;
        width: fit-content;
      `: `
        display: flex;
        width: max-content;
        margin-top: 10px;
      `}
    }
  }

  .select_date_header {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    ${props => props.ismedium === "true" ? `
    border-right: 1px solid ${COLORS.LIGHT_BLUE};
    `: `
    `}
    p {
      font-family: Colfax Bold !important;
      margin:0;
      margin-bottom: 10px;
    }
  }

    .menu_icon_wrapper {
      
      ${props => props.ismedium === "true" ? `
      width: 100px;
      `: `
      `}

      ${props => props.ismedium === "true" ? `
      button {
        padding: 20px;
          img {
          width: 50px;
        }
      }
      `: `
      button {
        padding: 15px;
        img {
          width: 30px;
        }
      }
      `}

  }
`;

const StyledCalWrapper = styled.div`
  max-width: 250px;
  height: 45px !important;
  input {
    font-size: 16px !important;
  }
`;

const Header = () => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 380,
        md: 960,
        lg: 1090,
        xl: 1536,
      },
    },
  });
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const navigate = useNavigate();

  const slug = window.location.pathname;
  if (slug.substring(1, 6) === "admin") {
    return null;
  }
  return (
    <StyledBar
      className="box-shadow-none bg-light-grey"
      position="fixed"
      color="transparent"
      ismedium={String(isMedium)}
    >
      <StyledToolbar disableGutters ismedium={String(isMedium)}>
        <StyledHeaderContainer>
          <a href={ROUTES.LANDING_PAGE} style={{ display: 'flex' }}>
            <StyledLogo src={Logo} alt="Hailscale" ismedium={String(isMedium)} />
          </a>
        </StyledHeaderContainer>
        <Grid item container direction="row" alignItems="center" style={{ flexFlow: "row", width: "min-content" }}>
          <Grid item>
            <div className="call_in_header">
              {isMedium ?
                <Fragment>
                  <p>Questions about roof inspections or insurance?</p>
                  <a href="tel:6124245420">Give us a call <span>(612)-424-5420</span></a>
                </Fragment>
                :
                <Fragment>
                  <a href="tel:6124245420"><img src={Phone_Black} alt="" className="height-20" /><span>(612)-424-5420</span></a>
                </Fragment>
              }
            </div>
          </Grid>
          {isMedium &&
            <Grid container item justifyContent="flex-end">
              <div className="select_date_header">
                <p>View roof inspection availability</p>
                <StyledCalWrapper>
                  <CalendarPicker sxStyles={{ "input": { backgroundColor: COLORS.GREY } }} onChange={(val) => navigate(ROUTES.SCHEDULE_FORM, { state: { date: dayjs(val) } })} />
                </StyledCalWrapper>
              </div>
            </Grid>
          }
          <Grid item container justifyContent="center" className={`menu_icon_wrapper ${isMenuOpen ? "bg-white" : ""}`}>
            <IconButton
              edge="end"
              aria-label="menu"
              onClick={toggleMenu}
              sx={{ paddingRight: "32px !important", ":hover": { backgroundColor: "transparent" } }}
            ><Box component={"div"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <DynamicHamburger isHamburger={!isMenuOpen} />
              </Box>
            </IconButton>
          </Grid>
        </Grid>
      </StyledToolbar>
      <Menu isOpen={isMenuOpen} toggleMenuCB={toggleMenu} />
    </StyledBar>
  );
};

export default Header;
