import React, { Fragment, useEffect } from "react";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import momentUtils from "@date-io/moment";
import { useAppointment } from "../Provider";
import styled from "styled-components";
import DateImg from "../../../assets/Date_Black.webp";
import { StyledLabel } from "../../Common/StyledElements";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { longerLeadTimeZipcodes } from "../../../constants/zipcodes";
import fedHolidays from '@18f/us-federal-holidays';

const holidays = fedHolidays.allForYear(moment().format("YYYY"));

const CalImg = styled.img`
    width: 26px;
    height: auto;
  `;

const StyledMuiPickersUtilsProvider = styled(MuiPickersUtilsProvider)`
    height: 45px !important;
  `;

export const AppointmentDate = ({ zipcode }) => {
  const { state, addApptDate } = useAppointment();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      addApptDate(moment(location.state.date.$d).format("YYYY-MM-DD"));
    }
    // eslint-disable-next-line
  }, [location])

  useEffect(() => {
    if (longerLeadTimeZipcodes.includes(Number(zipcode))) {
      let newDate = dayjs().add(4, "d")
      if (dayjs(newDate).day === 0) {
        newDate = dayjs().add(5, "d")
      }
      addApptDate(dayjs(newDate).format("YYYY-MM-DD"))
    }
    // eslint-disable-next-line
  }, [zipcode])

  return (
    <Fragment>
      <StyledLabel>Date<span className="text-red">*</span></StyledLabel>
      <StyledMuiPickersUtilsProvider utils={momentUtils}>
        <KeyboardDatePicker
          fullWidth
          autoOk
          variant="inline"
          className="bg-white border-radius-10"
          inputVariant="outlined"
          value={state.apptDate}
          format="MM/DD/YYYY"
          onChange={(date) => date ? addApptDate(date.format("YYYY-MM-DD")) : null}
          minDate={new Date()}
          shouldDisableDate={(date) =>
            date.day() === 0 ||
            date.format("MM/DD/YYYY") === moment().format("MM/DD/YYYY") ||
            holidays.some(item => item.dateString === date.format("YYYY-MM-DD")) ||
            date.format("MM/DD/YYYY") === moment().add(1, "days").format("MM/DD/YYYY") ||
            (longerLeadTimeZipcodes.includes(Number(zipcode)) && (
              date.format("MM/DD/YYYY") === moment().add(2, "days").format("MM/DD/YYYY") ||
              date.format("MM/DD/YYYY") === moment().add(3, "days").format("MM/DD/YYYY") ||
              date.format("MM/DD/YYYY") === moment().add(4, "days").format("MM/DD/YYYY")))
          }
          keyboardIcon={<CalImg src={DateImg} alt="keyboard icon" />}
        />
      </StyledMuiPickersUtilsProvider>
    </Fragment>
  );
};
