import axios from "axios";
import {
  POST_HAIL_SCALE_RATING,
  AUTHORIZE,
  GET_CONTRACTORS,
  GET_PARTNERS,
  INSPECTIONS,
  GET_INSURANCE_PROVIDERS,
  GET_PROPERTY_MANAGERS,
  APPOINTMENT,
  APPOINTMENTHOME,
  APPOINTMENT_INSPECTIONS,
  MONITORING,
  OUTOFAREA,
  CONTACT,
  ADDRESS,
  POST_CAMPAIGNS,
  CHUNKS,
  CHECK_ADDRESS,
  POST_BATCH_REPORT,
  GOOGLE_SHEET_REPORT,
  IP_ADDRESS,
  CHECK_IP_ADDRESS,
  GET_ZIPCODES,
  LOG_ERROR,
  ADD_TO_DIRECTORY,
  UPDATE_DIRECTORY,
  DELETE_FROM_DIRECTORY,
  UNSUBSCRIBE
} from "../constants/services";

export const postHailScaleRating = (params) => {
  return axios.post(POST_HAIL_SCALE_RATING, params);
};

export const authorize = (params) => {
  return axios.post(AUTHORIZE, params);
};

export const getContractors = (payload) => {
  return axios.post(GET_CONTRACTORS, payload);
};

export const postAddress = (payload) => {
  return axios.post(ADDRESS, payload);
};

export const getPreviusDayAddresses = () => {
  return axios.get(ADDRESS);
};

export const checkAddress = (payload) => {
  return axios.post(CHECK_ADDRESS, payload);
};

export const postBatchReport = (payload) => {
  return axios.post(POST_BATCH_REPORT, payload);
};

export const crunchAddresses = () => {
  return axios.get(GOOGLE_SHEET_REPORT);
};

export const getIPAddress = () => {
  return axios.get('https://api.ipgeolocation.io/getip');
};

export const checkIpAddress = (payload) => {
  return axios.post(CHECK_IP_ADDRESS, payload);
};

export const postIpAddress = (payload) => {
  return axios.post(IP_ADDRESS, payload);
};

export const getInsuranceProviders = () => {
  return axios.get(GET_INSURANCE_PROVIDERS);
};

export const getPropertyManagers = () => {
  return axios.get(GET_PROPERTY_MANAGERS);
};

export const submitInspection = (_id, payload) => {
  return axios.post(`${INSPECTIONS}/${_id}`, payload);
};

export const addPhotoChunk = (payload) => {
  return axios.post(`${CHUNKS}`, payload);
};

export const getChunk = (payload) => {
  return axios.post(`${CHUNKS}/get-chunk`, payload);
};

export const removePhotoChunks = (payload) => {
  return axios.post(`${CHUNKS}/delete-chunk`, payload);
};

export const submitAppointment = (payload) => {
  return axios.post(APPOINTMENT, payload);
};
export const getAppointment = (payload) => {
  return axios.post(APPOINTMENTHOME, payload);
};

export const submitMonitoring = (payload) => {
  return axios.post(MONITORING, payload);
};

export const submitOutOfArea = (payload) => {
  return axios.post(OUTOFAREA, payload);
};

export const submitContact = (payload) => {
  return axios.post(CONTACT, payload);
};

export const getAddressInspection = (geoLocation) => {
  return axios.get(`${INSPECTIONS}/${geoLocation.lng}/${geoLocation.lat}`);
};

export const deleteInspection = (_id) => {
  return axios.delete(`${INSPECTIONS}/${_id}`);
};

export const getArticles = () => {
  return axios.get("https://hailscale.wpcomstaging.com/wp-json/wp/v2/articles?per_page=100");
};
export const getRealtyPartnerImages = () => {
  return axios.get("https://hailscale.wpcomstaging.com/wp-json/acf/v3/partner");
};

export const getZipcodes = () => {
  return axios.get(GET_ZIPCODES);
};

export const getAppInspections = (payload) => {
  return axios.post(APPOINTMENT_INSPECTIONS, payload);
};

export const postError = (payload) => {
  return axios.post(LOG_ERROR, payload);
};

// ADMIN ROUTES
export const getActiveAppointments = () => {
  return axios.get(APPOINTMENT);
};

export const updateAppointment = (type, payload) => {
  return axios.put(`${APPOINTMENT}/${type}`, payload);
};

export const getPartners = (payload) => {
  return axios.post(GET_PARTNERS, payload);
};

export const updateDirectory = (payload) => {
  return axios.post(UPDATE_DIRECTORY, payload);
};

export const addToDirectory = (payload) => {
  return axios.post(ADD_TO_DIRECTORY, payload);
};
export const postCampaigns = (payload) => {
  return axios.post(POST_CAMPAIGNS, payload);
};

export const deleteFromDirectory = (payload) => {
  return axios.post(DELETE_FROM_DIRECTORY, payload);
};
export const unsubscribeFromHailscale = (payload) => {
  return axios.post(UNSUBSCRIBE, payload);
};