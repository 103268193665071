import React, { useState, Fragment, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { StyledLabel } from '../../Common/StyledElements';
import withLocalContext from '../../store/withLocalContext';
import { addRatingParams } from '../../store/actions';
import { getRatingParams } from '../../store/selectors';
import { StyledExpandMoreIcon } from '../../RoofAgeSelect';

export const Address = ({ context: { state, dispatch } }) => {
  const [value, setValue] = useState(null);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    options: {
      componentRestrictions: { country: ["us"] },
      types: ["address"],
    },
  });

  useEffect(() => {
    if (window.location.search) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.forEach(async (value, key) => {
        if (key === "ratingParams") {
          const ratingParams = JSON.parse(value);
          await dispatch(addRatingParams(ratingParams));
        }
      })
    }
  }, [dispatch])

  const addAddressToParams = (addy) => {

    placesService.getDetails(
      {
        placeId: addy.place_id,
      },
      (placeDetails) => {
        let addZipcode;
        placeDetails.address_components.map(component => {
          return component.types.map(type => {
            return type === "postal_code" &&
              (addZipcode = component.long_name)
          });
        });
        dispatch(
          addRatingParams({
            ...getRatingParams(state),
            address: {
              address: placeDetails.formatted_address,
              zipCode: addZipcode,
              shortAddress: `${placeDetails.address_components[0].long_name} ${placeDetails.address_components[1].long_name}`,
              placeId: addy.place_id,
              geoLocation: {
                lat: placeDetails.geometry.location.lat(),
                lng: placeDetails.geometry.location.lng(),
              },
            },
          })
        );
      }
    );
  }

  return (
    <Fragment>
      <StyledLabel>Address<span>*</span></StyledLabel>
      <Autocomplete
        id="google-map-demo"
        sx={{ width: "100%", "input": { "&::placeholder": { opacity: 1 } } }}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={placePredictions}
        loading={isPlacePredictionsLoading}
        autoComplete
        includeInputInList
        filterSelectedOptions
        popupIcon={<StyledExpandMoreIcon />}
        value={state.ratingParams.address ? state.ratingParams.address.address : value}
        noOptionsText="No locations"
        onChange={(evt, val) => {
          if (val) addAddressToParams(val);
          setValue(val);
        }}
        renderInput={(params) => (
          <TextField
            onChange={(evt) => getPlacePredictions({ input: evt.target.value })} {...params}
            placeholder='Address...'
            fullWidth
          />
        )}
        renderOption={(props, option) => {
          let matches = "";
          let parts = [];
          if (option.structured_formatting) {

            matches = option.structured_formatting.main_text_matched_substrings || [];

            parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [match.offset, match.offset + match.length]),
            );
          }

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}

                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting ? option.structured_formatting.secondary_text : option}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </Fragment>
  );
}

export default withLocalContext(Address);
