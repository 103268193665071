import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { submitMonitoring, postError } from "../../services";
import styled from "styled-components";
import Input from "../Common/InputField";
import { ROUTES } from "../../constants/routes";
import { useMonitor } from "./Provider";
import BasicSelect from "../Common/HSCSelect";
import { options } from "../RoofAgeSelect";
import CustomCheckbox from "../Common/CustomCheckbox";
import AddressLookupInput from "./AddressLookup";

const StyledBtnsContainer = styled(Grid)`
  @media (max-width: 960px) {
    button {
      width: 100%;
    }
  }
`;

const MonitorForm = ({ deviceData }) => {

  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    state,
    addAddress,
    addRoofAge,
    addPropertyType,
    addEmail,
    addContactPreferences,
    addFullName,
    addPhoneNumber,
    addPromos,
    clearForm
  } = useMonitor();

  const addContactOptions = (option) => {
    if (state.contactPreferences.includes(option)) {
      addContactPreferences(state.contactPreferences.filter((opt) => opt !== option));
    } else {
      addContactPreferences([...state.contactPreferences, option]);
    }
  }

  const addPromoOptions = (option) => {
    let inputValue = option === "yes" ? true : false;
    addPromos(inputValue);
  }


  const AddMonitoring = async () => {

    if (!state.fullName || !state.email || !state.address || !state.roofAge || !state.propertyType || state.contactPreferences.length === 0) {
      setIsValid(false);
    } else {
      setIsValid(true);
      try {
        await submitMonitoring(state);
        setIsSubmitted(true);
      } catch (err) {
        console.log(err);
        postError({ error: err, device: deviceData });
        navigate(ROUTES.ERROR);
      }
    }
  }

  return (
    <Grid container spacing={isMedium ? 4 : 2} alignItems="flex-end">
      <Grid item xs={12} md={4}>
        <AddressLookupInput address={addAddress} />
      </Grid>
      <Grid item container xs={12} md={4}>
        <BasicSelect
          text={"Roof age"}
          label="Choose..."
          selectValue={state.roofAge}
          change={(val) => addRoofAge(val.target.value)}
          listMap={options}
        />
      </Grid>
      <Grid item container xs={12} md={4}>
        <BasicSelect
          text={"This property is my:"}
          label="Choose..."
          selectValue={state.propertyType}
          change={(val) => addPropertyType(val.target.value)}
          listMap={["Home", "Rental property", "Other"]}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          isRequired
          text="Full name"
          placeholder="Your full name..."
          textValue={state.fullName}
          change={(event) => addFullName(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={4} className="">
        <Input
          isRequired
          text="Email"
          placeholder="Your email..."
          type="email"
          textValue={state.email}
          change={(event) => addEmail(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={4} className="">
        <Input
          text="Phone number"
          placeholder="Your phone number..."
          textValue={state.phoneNumber}
          change={(event) => addPhoneNumber(event.target.value)}
        />
      </Grid>
      <Grid item container xs={12} md={4}>
        <CustomCheckbox
          title="Notify me by (select all that apply)"
          setCheckBox={addContactOptions}
          options={[{ value: "email", description: "Email" }, { value: "text", description: "Text" }]}
        />
      </Grid>
      <Grid item container xs={12} md={4}>
        <CustomCheckbox
          singleSelect
          title="Do you want to recieve Hailscale promos and announcements?"
          setCheckBox={addPromoOptions}
          options={[{ value: "yes", description: "Yes", groupName: "promos" }, { value: "no", description: "No", groupName: "promos" }]}
        />
      </Grid>
      <StyledBtnsContainer item container xs={12} md={4} alignItems="flex-end" style={{ gap: 22 }} className={!isMedium && "m-t-15"}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => clearForm({})}
        >Cancel</Button>
        <Button
          sx={{ padding: "0 20px" }}
          onClick={() => AddMonitoring()}
        >Enroll</Button>
        {!isValid ?
          <Typography className="text-red">Please fill out all required fields</Typography>
          :
          ""
        }
        {isSubmitted ?
          <Typography className="text-green">Congrats! You will remain in the<br />loop for your home’s hail history.</Typography>
          :
          ""
        }
      </StyledBtnsContainer>
    </Grid>
  );
};

export default MonitorForm;