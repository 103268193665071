import { useEffect } from "react";
import moment from "moment";
import { getArticles, getZipcodes, postError } from "../../services";
import { addArticles, addZipcodes, addDeviceData } from "../store/actions";
import withLocalContext from "../store/withLocalContext";
import { ROUTES } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { isMobile, deviceDetect } from "react-device-detect";

export const GlobalLoad = ({ context: { dispatch } }) => {
  const navigate = useNavigate();

  useEffect(() => {
    setGlobalState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setGlobalState = async () => {
    let deviceDetails = deviceDetect();
    let devicePayload = {
      isMobile: isMobile,
      browserFullVersionModel: isMobile ? deviceDetails.model : deviceDetails.browserFullVersion,
      browserNameVendor: isMobile ? deviceDetails.vendor : deviceDetails.browserName,
      osName: isMobile ? deviceDetails.os : deviceDetails.osName,
      osVersion: deviceDetails.osVersion,
      userAgent: isMobile ? deviceDetails.ua : deviceDetails.userAgent,
      url: window.location.href,
      date: moment().format(),
    };

    try {
      const articleRes = await getArticles();
      dispatch(addArticles(articleRes.data));

      let zipRes;

      zipRes = await getZipcodes();
      if (!zipRes) {
        postError({ error: "zipCodes did not load the first time", device: devicePayload });
        setTimeout(async () => {
          zipRes = await getZipcodes();
          if (!zipRes) {
            postError({ error: "zipCodes did not load the second time", device: devicePayload });
            zipRes.data = [];
          }
          dispatch(addZipcodes(zipRes.data));
        }, 2000);
      }
      dispatch(addZipcodes(zipRes.data));
      dispatch(addDeviceData(devicePayload));

    } catch (err) {
      postError({ error: err, device: devicePayload });
      postError({ error: 'error globalstate', device: devicePayload });
      navigate(ROUTES.ERROR);

    }
  };

  return null;

}

export default withLocalContext(GlobalLoad);