import React, { Fragment, useState } from "react";
import withLocalContext from "../store/withLocalContext";
import ReportBtn from "./ReportBtn";
import { HailScaleModal } from "../Common/HailScaleModal";
import OutOfAreaModalContent from "./OutOfAreaModalContent";
import IPWarningModalContent from "./IPWarningModalContent";
import IPDeniedModalContent from "./IPDeniedModalContent";
import { getAddressParam, getIsAuthorized, getRoofAgeParam } from "../store/selectors";
import {
  checkAddress,
  postAddress,
  getIPAddress,
  postIpAddress,
  checkIpAddress,
  postError
} from "../../services";
import { ROUTES } from "../../constants/routes";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const StyledBtnWrapper = styled.div`
  button {
    ${(props) => props.isDisabled === "true" && "pointer-events: none;"}
  }
`;

export const SubmitReportBtn = ({ context: { state } }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIPWarningModalOpen, setIsIPWarningModalOpen] = useState(false);
  const [isIPDeniedModalOpen, setIsIPDeniedModalOpen] = useState(false);
  const [dailyCount, setDailyCount] = useState(0);
  const [ipAddress, setIpAddress] = useState();
  const [zipcodeToCheck, setZipcodeToCheck] = useState("");
  const [address, setAddress] = useState("");
  const [isDisabled, setIsDisbaled] = useState(false);

  const SubmitRating = async () => {
    setIsDisbaled(true);
    try {
      const ipAddressRes = await getIPAddress();
      setIpAddress(ipAddressRes.data.ip);

      let ipCheckRes = await checkIpAddress({ ipAddress: ipAddressRes.data.ip });
      setDailyCount(ipCheckRes.data.daily_count);

      const campaignRes = await checkForCampaigns();
      const zipCodeRes = await checkZipCodes();
      postAddress(
        {
          address: state.ratingParams.address.address,
          campaigns: campaignRes.data.campaigns,
          recent_inspection: campaignRes.data.recent_inspection,
          latitude: campaignRes.data.geometry.lat,
          longitude: campaignRes.data.geometry.lng,
          roof_age: state.ratingParams.roofAge,
          in_market: zipCodeRes ? true : false,
        }
      );
      postError({ error: 'checked roof', device: state.deviceData });
      if (getIsAuthorized()) {
        ipCheckRes.data.daily_count = 0;
      }
      if (zipCodeRes) {
        if (ipCheckRes.data.daily_count < 8) {
          if (ipCheckRes.data.daily_count >= 5) {
            setIsIPWarningModalOpen(true);
          } else {
            postIpAddress({ dailyCount: ipCheckRes.data.daily_count + 1, ipAddress: ipAddressRes.data.ip });
            if (getAddressParam(state) && getRoofAgeParam(state)) {
              navigate(ROUTES.LOADING_REPORT);
            }
          }
        } else {
          setIsIPDeniedModalOpen(true);
        }
      } else {
        setIsModalOpen(true);
      }
    } catch (err) {
      console.log("error: ", err);
      postError({ error: err, device: state.deviceData });
      navigate(ROUTES.ERROR);
    }
  };

  const checkForCampaigns = async () => {
    try {
      const addressRes = await checkAddress({ address: state.ratingParams.address });
      return addressRes;
    } catch (err) {
      console.log('error', err);
      postError({ error: err, device: state.deviceData });
      navigate(ROUTES.ERROR);
    }
  }

  const checkZipCodes = () => {
    let zipcode = state.ratingParams.address.zipCode;
    setZipcodeToCheck(zipcode);
    setAddress(state.ratingParams.address.address);
    let check = false;
    state.zipcodes.map(zip_code => {
      if (zip_code.zip_code.toString() === zipcode) {
        check = true;
      }
      return check;
    });
    return check;
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeIPWarningModal = () => {
    setIsIPWarningModalOpen(false);
  };
  const closeIPDeniedModal = () => {
    setIsIPDeniedModalOpen(false);
  };

  return (
    <Fragment>
      <StyledBtnWrapper isDisabled={String(isDisabled)}>
        <ReportBtn onSubmit={SubmitRating} />
      </StyledBtnWrapper>
      <HailScaleModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        children={
          <OutOfAreaModalContent
            zipcode={zipcodeToCheck}
            address={address}
            closeModal={closeModal}
            deviceData={state.deviceData}
          />
        }
      />
      <HailScaleModal
        isOpen={isIPWarningModalOpen}
        onClose={() => setIsIPWarningModalOpen(false)}
        children={
          <IPWarningModalContent
            dailyCount={dailyCount}
            ipAddress={ipAddress}
            closeModal={closeIPWarningModal}
            modalState={state}
          />
        }
      />
      <HailScaleModal
        isOpen={isIPDeniedModalOpen}
        onClose={() => setIsIPDeniedModalOpen(false)}
        children={
          <IPDeniedModalContent
            closeModal={closeIPDeniedModal}
          />
        }
      />
    </Fragment>
  );
};

export default withLocalContext(SubmitReportBtn);
